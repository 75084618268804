@media only screen and (max-width: 1600px) {
    .streaming-video-container {
        width: 50% !important;

    }
}

@media only screen and (max-width: 1200px) {
    .streaming-video-container {
        width: 60% !important;
    }

    .menu-container {
        display: block !important;
    }

    .btns-container {
        width: 100%;
        margin: 0 auto;
        max-width: 100%;
        text-align: center;
        margin-bottom: 30px;
    }

    .user-data-tab,
    .credit-tab,
    .add-credit-tab,
    .management-seers-tab,
    .management-rooms-tab,
    .management-promotions-tab {
        width: 100% !important;
        max-width: 90%;
        margin: 0 auto;
    }

    .add-credit-tab .btns-container {
        width: 50%;
        margin: 16px auto;
    }

    .add-credit-tab .credit-buttons-row .template-btn {
        width: 25% !important;
    }

    .add-credit-tab .inputs-container {
        width: 80%;
    }

    .template-btn {
        font-size: 12px;
    }

    .seers-table a,
    .seer-rooms-table a,
    .promotions-table a,
    .billing-table a,
    .posts-table a,
    .private-sessions-table a {
        width: 40% !important;
        text-align: center;
        font-size: 14px;
        padding-left: 11px !important;
    }

    .seers-table th,
    .seers-table td,
    .seer-rooms-table th,
    .seer-rooms-table td,
    .promotions-table th,
    .promotions-table td,
    .private-sessions-table th,
    .private-sessions-table td
     {
        font-size: 11px;
    }

    .online-users-container .online-users .user .user-data-container  .user-audio-invalid-message {
        float: none !important;
        position: unset !important;    
        font-weight: bold;
        font-size: 13px;
        margin-top: 10px;
    }
}

@media only screen and (max-width: 767px) {
    
    $all-size: 15px;
    /*-- Default CSS --*/
    body {
        font-size: $all-size;
    }
    .ptb-100 {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    .pt-100 {
        padding-top: 50px;
    }

    .pt-50 {
        padding-top: 40px;
    }

    .pb-70 {
        padding-bottom: 20px;
    }
    .pb-100 {
        padding-bottom: 50px;
    }
    /*-- End Default CSS --*/

    /*----- Home One CSS -----*/
    /*-- Navbar --*/
    .banner-area-two .banner-social {
        top: 620px !important;
    }

    .side-nav {
        position: absolute;
        top: 8px;
        left: 80px;

        .modal-btn {
            span {
                &:nth-child(2) {
                    width: 25px;
                }
                &:nth-child(3) {
                    width: 15px;
                }
            }
        }
    }
    .nav-srh {
        .search-area {
            left: -110px;

            .src-input {
                width: 250px;
            }
            .search-icon {
                left: 18px;
                top: 20px;
            }
        }
        .search-area.opened {
            padding: 10px 10px;
        }
    }
    .side-nav-two {
        position: absolute;
        top: 8px;
        left: 65px;

        a {
            font-size: 14px;
            padding: 8px 10px 10px;
        }
    }
    .mobile-nav .logo {
        max-width: 90px;
    }
    .mobile-nav.mean-container .mean-nav ul li a.active {
        color: #c1ab65;
    }
    .mean-container .mean-nav .navbar-nav {
        height: 370px;
        overflow-y: auto;
    }
    .main-nav {
        .navbar-nav {
            height: 350px;
            overflow-y: scroll;
        }
    }
    .mean-container a.meanmenu-reveal span {
        position: relative;
        top: 8px;
    }

    .mean-container a.meanmenu-reveal {
        right: 20px !important;
        left: auto !important;
    }

    .mobile-nav .logo {
        max-width: 90px;
        right: 0;
        left: 20px;
    }

    .mean-container .mean-nav .navbar-nav {
        height: 325px !important;
    }

    .mean-container .mean-nav ul {
        margin: 25px 0px !important;
    }

    .mean-container .mean-bar {
        height: 83px !important;
    }

    .login-button {
        display: none;
    }

    /*-- End Navbar --*/

    /*-- Banner --*/
    .banner-social {
        top: 395px;
        right: 0;
        left: 0;
        text-align: center;
        transform: rotate(0deg);
    }
    .banner-area {
        height: 100%;
        padding-top: 170px;
        padding-bottom: 180px;
    }
    .banner-content {
        margin-top: 0;

        h1 {
            font-size: 34px;
            margin-bottom: 10px;
        }
        p {
            font-size: 16px;
            margin-bottom: 30px;
        }
    }
    /*-- End Banner --*/

    /*-- Trust --*/
    .section-title {
        margin-bottom: 30px;
        margin-top: -7px;

        .sub-title {
            font-size: 14px;
            margin-bottom: 12px;
        }
        h2 {
            font-size: 25px;
        }
    }
    /*-- End Trust --*/

    /*-- Week --*/
    .week-area {
        .week-item {
            a {
                width: 60px;
                height: 60px;
                line-height: 60px;

                i {
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                }
            }
        }
        .owl-prev {
            display: none !important;
        }
        .owl-next {
            display: none !important;
        }
    }
    .week-video-btn {
        a {
            width: 50px !important;
            height: 50px !important;
            line-height: 50px !important;
            top: 22% !important;

            i {
                width: 55px !important;
                height: 50px !important;
                line-height: 54px !important;
            }
        }
    }
    /*-- End Week --*/

    /*-- Work --*/
    .work-item {
        .work-content {
            bottom: 30px;

            h3 {
                font-size: 20px;
                margin-bottom: 2px;
            }
            span {
                font-size: 18px;
            }
        }
    }
    /*-- End Work --*/

    /*-- History --*/
    .history-area {
        .history-img {
            margin-left: auto;
            margin-right: auto;
        }
        .history-content {
            text-align: center;

            h2 {
                font-size: 25px;
                margin-bottom: 8px;
            }
            p {
                margin-left: auto;
                margin-right: auto;
            }
            .history-inner {
                h3 {
                    font-size: 20px;
                }
            }
        }
        .timeline {
            max-width: 100%;
            margin-bottom: 5px;

            .events-wrapper {
                .events {
                    ul {
                        li {
                            a {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    /*-- End History --*/

    /*-- Video --*/
    .video-item {
        text-align: center;

        a {
            width: 80px;
            height: 80px;
            line-height: 70px;
            font-size: 55px;

            i {
                width: 80px;
                height: 80px;
                line-height: 70px;
            }
        }
    }
    /*-- End Video --*/

    /*-- Blog --*/
    .blog-item {
        text-align: center;
    }
    /*-- End Blog --*/

    /*-- Copyright --*/
    .copyright-area {
        padding-top: 20px;
        padding-bottom: 20px;

        .copyright-item {
            p {
                font-size: 14px;
            }
        }
    }
    /*-- End Copyright --*/
    /*----- End Home One CSS -----*/

    /*----- Back to Top CSS ------*/
    #toTop {
        bottom: 70px;
    }
    .back-to-top-btn {
        i {
            margin-left: 20px;
        }
    }
    /*----- End Back to Top CSS -----*/

    /*----- Home Two CSS -----*/
    /*-- Banner --*/
    .banner-area-two {
        height: 100%;
        padding-top: 170px;
        padding-bottom: 180px;

        .banner-content {
            margin-top: 0;

            h1 {
                font-size: 28px;
                margin-bottom: 10px;
            }
            p {
                font-size: 18px;
                margin-bottom: 30px;
            }
        }
        .banner-social {
            top: 435px;
        }
    }
    /*-- End Banner --*/

    /*-- Team --*/
    .team-area-two {
        padding-top: 50px;
    }
    /*-- End Team --*/

    /*-- Product --*/
    .product-item {
        h3 {
            font-size: 20px;
        }
        ul {
            li {
                a {
                    font-size: 14px;
                }
            }
        }
        p {
            padding-left: 15px;
            padding-right: 15px;
        }
    }
    /*-- End Product --*/
    /*----- End Home Two CSS -----*/

    /*----- About CSS -----*/
    /*-- Page Title --*/
    .page-title-area {
        .page-title-item {
            padding-top: 130px;
            padding-bottom: 95px;

            h2 {
                font-size: 25px;
                margin-bottom: 5px;
            }
        }
    }
    /*-- End Page Title --*/
    /*----- End About CSS -----*/

    /*----- About Me CSS -----*/
    /*-- About Me --*/
    .about-me-area {
        .about-me-content {
            h2 {
                font-size: 25px;
                margin-bottom: 8px;
            }
            ul {
                margin-bottom: 15px;

                li {
                    font-size: 16px;
                }
            }
        }
    }
    /*-- End About Me --*/
    /*----- End About Me CSS -----*/

    /*----- blog Details CSS -----*/
    .blog-details-item {
        .blog-details-img {
            a {
                top: 30%;
            }
        }
        .blog-details-content {
            margin-bottom: 50px;

            ul {
                li {
                    font-size: 14px;
                }
            }
            h3 {
                font-size: 20px;
            }
        }
        .blog-details-quote {
            p {
                font-size: 16px;
            }
        }
        .blog-details-work {
            h3 {
                font-size: 20px;
            }
            .blog-inner {
                h4 {
                    font-size: 17px;
                }
                span {
                    display: inline-block;
                }
            }
        }
        .blog-details-cate {
            h3 {
                font-size: 20px;
            }
        }
    }
    /*----- End blog Details CSS -----*/

    /*----- Video Details CSS -----*/
    .video-details-area {
        .video-details-item {
            .video-details-video {
                a {
                    top: 32%;
                    width: 70px;
                    height: 70px;

                    i {
                        width: 70px;
                        height: 70px;
                        line-height: 70px;
                    }
                }
            }
            .video-content {
                margin-bottom: 30px;

                h2 {
                    font-size: 25px;
                }
                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        margin-left: 15px;
                    }
                }
            }
            .video-flim {
                h3 {
                    font-size: 20px;
                }
            }
            .video-social {
                ul {
                    li {
                        a {
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
    /*----- End Video Details CSS -----*/

    /*----- Blog Details CSS -----*/
    .blog-details-form {
        .form-group {
            .form-control {
                height: 45px;
            }
        }
        .blog-details-btn {
            font-size: 14px;
            padding: 10px 20px;
        }
    }
    /*----- End Blog Details CSS -----*/

    /*----- FAQ CSS -----*/
    .faq-area {
        .faq-head {
            h2 {
                margin-bottom: 25px;
                font-size: 25px;
            }
        }
        .accordion {
            p {
                font-size: $all-size;
                padding: 20px 20px 15px 20px;
            }
            a {
                font-size: 14px;
                padding: 15px 18px 15px 32px;
            }
        }
    }
    /*----- End FAQ CSS -----*/

    /*----- Error CSS -----*/
    .error-item {
        height: 465px;

        h1 {
            font-size: 90px;
            margin-bottom: 0;
        }
        p {
            font-size: 20px;
            margin-top: -10px;
            margin-bottom: 12px;
        }
        span {
            display: block;
            color: #646464;
        }
        a {
            padding: 15px 35px;
            margin-top: 40px;
            font-size: 17px;
        }
    }
    /*----- End Error CSS -----*/

    /*----- Coming Soon CSS -----*/
    .coming-item {
        height: 100%;
        padding-top: 110px;
        padding-bottom: 110px;

        h1 {
            font-size: 35px;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 30px;
        }
        .coming-wrap {
            .coming-inner {
                h3 {
                    font-size: 35px;
                    margin-bottom: 0;
                }
                p {
                    font-size: 15px;
                    margin-top: -5px;
                }
            }
        }
    }
    /*----- End Coming Soon CSS -----*/

    /*----- Privacy Policy CSS -----*/
    .legal-advice-area {
        padding-bottom: 0;
    }
    .privacy-item {
        h2 {
            font-size: 25px;
        }
    }
    /*----- End Privacy Policy CSS -----*/

    /*----- Contact CSS -----*/
    .contact-area {
        .contact-item {
            h2 {
                font-size: 25px;
                margin-bottom: 15px;
            }
            .form-group {
                .form-control {
                    height: 45px;
                }
            }
            .contact-address {
                .contact-inner {
                    padding-right: 40px;

                    .icon {
                        font-size: 25px;
                    }
                }
            }
        }
    }
    .map-area {
        #map {
            height: 350px;
        }
    }
    .work-area-three {
        padding-right: 0;
        padding-left: 0;
    }
    .blog-area-two {
        .blog-item {
            .blog-bottom {
                padding: 25px 15px 30px;
            }
        }
    }
    /*----- End Contact CSS -----*/

    /*------ Footer -------------*/
    .footer {
        text-align: center;
    }

    footer .footer-item .footer-touch ul li {
        padding-right: 0;
    }

    /*------ END Footer -------------*/

    /*------ Seers List -------------*/
    .seers-list-area .seer-list-data-container {
        width: 90% !important;
        margin-left: 0px !important;
        margin-top: 15px;
    }

    .seers-list-area .seers-list-item {
        text-align: center;
    }

    .seers-list-area .seer-image {
        float: none !important;
    }

    /*------ END Seers List -------------*/

    /*------ Login/Register -------------*/
    .login-container,
    .register-container,
    .recover-password-container {
        max-width: 350px !important;
    }

    .toggle label {
        font-size: 12px;
    }

    .register-area .buttons-container .btn-send {
        width: 100% !important;
        margin-bottom: 15px;
    }

    .register-area .buttons-container .btn-cancel {
        width: 100% !important;
    }

    .recover-password-area .buttons-container {
        width: 100% !important;
    }

    /*------ END Login/Register -------------*/

    .streaming-video-container {
        width: 80% !important;
    }

    .streaming-video-frame {
        height: 315px !important;
    }

    .template-btn{
        margin-right: 0px !important;
    }

    .streaming-video-container {
        margin-bottom: 0px !important;
    }

    .btn-responsive{
        margin-right: 10px !important;
    }

    .cookies-advice-area{
        font-size: 7px;
    }
    
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
    $all-size: 15px;
    /*-- Default CSS --*/
    body {
        font-size: $all-size;
    }
    .ptb-100 {
        padding-top: 70px;
        padding-bottom: 70px;
    }
    .pt-100 {
        padding-top: 70px;
    }

    .pt-50 {
        padding-top: 45px;
    }

    .pb-70 {
        padding-bottom: 40px;
    }
    .pb-100 {
        padding-bottom: 70px;
    }
    /*-- End Default CSS --*/

    /*----- Home One CSS -----*/
    /*-- Navbar --*/
    .side-nav {
        position: absolute;
        top: 8px;
        left: 80px;

        .modal-btn {
            span {
                &:nth-child(2) {
                    width: 25px;
                }
                &:nth-child(3) {
                    width: 15px;
                }
            }
        }
    }
    .nav-srh {
        .search-area {
            .src-input {
                width: 250px;
            }
            .search-icon {
                left: 18px;
                top: 20px;
            }
        }
        .search-area.opened {
            padding: 10px 10px;
        }
    }
    .side-nav-two {
        position: absolute;
        top: 8px;
        left: 65px;

        a {
            font-size: 14px;
            padding: 8px 10px 10px;
        }
    }
    .mobile-nav .logo {
        max-width: 90px;
        right: 0;
        left: 20px;
    }
    .mobile-nav.mean-container .mean-nav ul li a.active {
        color: #c1ab65;
    }
    .mean-container .mean-nav .navbar-nav {
        height: 375px;
        overflow-y: auto;
    }
    .main-nav {
        .navbar-nav {
            height: 350px;
            overflow-y: scroll;
        }
    }

    .mean-container a.meanmenu-reveal {
        right: 20px !important;
        left: auto !important;
    }

    .mean-container a.meanmenu-reveal span {
        position: relative;
        top: 8px;
    }

    .mean-container .mean-nav .navbar-nav {
        height: 325px !important;
    }

    .mean-container .mean-nav ul {
        margin: 25px 0px !important;
    }

    .mean-container .mean-bar {
        height: 83px !important;
    }

    .login-button {
        display: none;
    }

    /*-- End Navbar --*/

    /*-- Banner --*/
    .banner-social {
        top: 360px;
        right: 0;
        left: 0;
        text-align: center;
        transform: rotate(0deg);
    }
    .banner-area {
        height: 100%;
        padding-top: 145px;
        padding-bottom: 220px;
    }
    .banner-content {
        margin-top: 0;

        h1 {
            font-size: 45px;
            margin-bottom: 10px;
        }
        p {
            font-size: 22px;
            margin-bottom: 30px;
        }
    }
    /*-- End Banner --*/

    /*-- Trust --*/
    .section-title {
        margin-bottom: 30px;
        margin-top: -7px;

        .sub-title {
            font-size: 14px;
            margin-bottom: 12px;
        }
        h2 {
            font-size: 25px;
        }
    }
    /*-- End Trust --*/

    /*-- Week --*/
    .week-area {
        .week-item {
            a {
                width: 60px;
                height: 60px;
                line-height: 60px;

                i {
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                }
            }
        }
        .owl-prev {
            display: none !important;
        }
        .owl-next {
            display: none !important;
        }
    }
    /*-- End Week --*/

    /*-- Work --*/
    .work-item {
        .work-content {
            bottom: 30px;

            h3 {
                font-size: 20px;
                margin-bottom: 2px;
            }
            span {
                font-size: 18px;
            }
        }
    }
    /*-- End Work --*/

    /*-- History --*/
    .history-area {
        .history-img {
            margin-left: auto;
            margin-right: auto;
        }
        .history-content {
            text-align: center;

            h2 {
                font-size: 25px;
                margin-bottom: 8px;
            }
            p {
                margin-left: auto;
                margin-right: auto;
            }
            .history-inner {
                h3 {
                    font-size: 20px;
                }
            }
        }
        .timeline {
            max-width: 100%;
            margin-bottom: 5px;

            .events-wrapper {
                .events {
                    ul {
                        li {
                            a {
                                font-size: 16px;
                            }
                        }
                    }
                }
            }
        }
    }
    /*-- End History --*/

    /*-- Video --*/
    .video-item {
        text-align: center;

        a {
            width: 80px;
            height: 80px;
            line-height: 70px;
            font-size: 55px;
            top: 44%;

            i {
                width: 80px;
                height: 80px;
                line-height: 70px;
            }
        }
    }
    /*-- End Video --*/

    /*-- Blog --*/
    .blog-item {
        text-align: center;
    }
    /*-- End Blog --*/

    /*-- Copyright --*/
    .copyright-area {
        padding-top: 20px;
        padding-bottom: 20px;

        .copyright-item {
            p {
                font-size: 14px;
            }
        }
    }
    /*-- End Copyright --*/
    /*----- End Home One CSS -----*/

    /*----- Home Two CSS -----*/
    /*-- Banner --*/
    .banner-area-two {
        height: 100%;
        padding-top: 170px;
        padding-bottom: 180px;

        .banner-content {
            margin-top: 170px;

            h1 {
                font-size: 36px;
                margin-bottom: 10px;
            }
            p {
                font-size: 22px;
                margin-bottom: 30px;
            }
        }
        .banner-social {
            top: 385px;
        }
    }
    /*-- End Banner --*/

    /*-- Team --*/
    .team-area-two {
        padding-top: 70px;
    }
    /*-- End Team --*/

    /*-- Product --*/
    .product-item {
        h3 {
            font-size: 20px;
        }
        ul {
            li {
                a {
                    font-size: 14px;
                }
            }
        }
    }
    /*-- End Product --*/
    /*----- End Home Two CSS -----*/

    /*----- About CSS -----*/
    /*-- Page Title --*/
    .page-title-area {
        .page-title-item {
            padding-top: 130px;
            padding-bottom: 95px;

            h2 {
                font-size: 25px;
                margin-bottom: 5px;
            }
        }
    }
    /*-- End Page Title --*/
    /*----- End About CSS -----*/

    /*----- About Me CSS -----*/
    /*-- About Me --*/
    .about-me-area {
        .about-me-content {
            h2 {
                font-size: 25px;
                margin-bottom: 8px;
            }
            ul {
                margin-bottom: 15px;

                li {
                    font-size: 16px;
                }
            }
        }
    }
    /*-- End About Me --*/
    /*----- End About Me CSS -----*/

    /*----- blog Details CSS -----*/
    .blog-details-item {
        .blog-details-img {
            a {
                top: 30%;
            }
        }
        .blog-details-content {
            margin-bottom: 50px;

            ul {
                li {
                    font-size: 14px;
                }
            }
            h3 {
                font-size: 20px;
            }
        }
        .blog-details-quote {
            p {
                font-size: 16px;
            }
        }
        .blog-details-work {
            h3 {
                font-size: 20px;
            }
            .blog-inner {
                h4 {
                    font-size: 17px;
                }
                span {
                    display: inline-block;
                }
                ul {
                    li {
                        flex: 0 0 20%;
                        max-width: 20%;

                        &:last-child {
                            flex: 0 0 30%;
                            max-width: 30%;
                        }
                    }
                }
            }
        }
        .blog-details-cate {
            h3 {
                font-size: 20px;
            }
        }
    }
    /*----- End blog Details CSS -----*/

    /*----- Video Details CSS -----*/
    .video-details-area {
        .video-details-item {
            .video-details-video {
                a {
                    top: 32%;
                    width: 70px;
                    height: 70px;

                    i {
                        width: 70px;
                        height: 70px;
                        line-height: 70px;
                    }
                }
            }
            .video-content {
                margin-bottom: 30px;

                h2 {
                    font-size: 25px;
                }
                ul {
                    margin: 0;
                    padding: 0;

                    li {
                        margin-left: 15px;
                    }
                }
            }
            .video-flim {
                h3 {
                    font-size: 20px;
                }
            }
            .video-social {
                ul {
                    li {
                        a {
                            font-size: 17px;
                        }
                    }
                }
            }
        }
    }
    /*----- End Video Details CSS -----*/

    /*----- Blog Details CSS -----*/
    .blog-details-form {
        .form-group {
            .form-control {
                height: 45px;
            }
        }
        .blog-details-btn {
            font-size: 14px;
            padding: 10px 20px;
        }
    }
    /*----- End Blog Details CSS -----*/

    /*----- FAQ CSS -----*/
    .faq-area {
        .faq-head {
            h2 {
                margin-bottom: 25px;
                font-size: 25px;
            }
        }
        .accordion {
            p {
                font-size: $all-size;
                padding: 20px 20px 15px 20px;
            }
            a {
                font-size: 14px;
                padding: 15px 18px 15px 32px;
            }
        }
    }
    /*----- End FAQ CSS -----*/

    /*----- Error CSS -----*/
    .error-item {
        height: 465px;

        h1 {
            font-size: 90px;
            margin-bottom: 0;
        }
        p {
            font-size: 20px;
            margin-top: -10px;
            margin-bottom: 12px;
        }
        span {
            display: block;
            color: #646464;
        }
        a {
            padding: 15px 35px;
            margin-top: 40px;
            font-size: 17px;
        }
    }
    /*----- End Error CSS -----*/

    /*----- Coming Soon CSS -----*/
    .coming-item {
        height: 100%;
        padding-top: 110px;
        padding-bottom: 110px;

        h1 {
            font-size: 35px;
            margin-bottom: 10px;
        }
        p {
            margin-bottom: 30px;
        }
        .coming-wrap {
            .coming-inner {
                h3 {
                    font-size: 35px;
                    margin-bottom: 0;
                }
                p {
                    font-size: 15px;
                    margin-top: -5px;
                }
            }
        }
    }
    /*----- End Coming Soon CSS -----*/

    /*----- Privacy Policy CSS -----*/
    .legal-advice-area {
        padding-bottom: 20px;
    }
    .privacy-item {
        h2 {
            font-size: 25px;
        }
    }
    /*----- End Privacy Policy CSS -----*/

    /*----- Contact CSS -----*/
    .contact-area {
        .contact-item {
            h2 {
                font-size: 25px;
                margin-bottom: 15px;
            }
            .form-group {
                .form-control {
                    height: 45px;
                }
            }
            .contact-address {
                .contact-inner {
                    padding-right: 40px;

                    .icon {
                        font-size: 25px;
                    }
                }
            }
        }
    }
    .map-area {
        #map {
            height: 350px;
        }
    }
    /*----- End Contact CSS -----*/
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
    .week-area {
        .owl-prev {
            right: 5px;
        }
        .owl-next {
            left: 5px;
        }
    }
    .blog-item .blog-bottom {
        padding: 25px 15px 30px 12px;
    }
    .blog-item .blog-bottom h3 {
        font-size: 19px;
    }
    .main-nav nav .navbar-nav .nav-item a {
        margin-left: 10px;
        margin-right: 10px;
    }
}

@media only screen and (min-width: 1800px) {
    .week-area .owl-prev {
        right: -90px;
    }
    .week-area .owl-next {
        left: -90px;
    }
}

@media only screen and (min-width: 576px) and (max-width: 767px) {
    .blog-area-two .blog-item .blog-bottom {
        padding: 25px 10px 30px;
    }
    .blog-area-two .blog-item .blog-bottom h3 {
        font-size: 17px;
    }
    .blog-area-two .blog-item .blog-bottom p {
        font-size: 14px;
    }
}
